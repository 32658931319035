<template>
  <b-card>
    <b-tabs
      v-model="lastTab"
      @input="changeTab"
    >
      <b-tab
        title="รัฐบาล"
        lazy
      >
        <LottoResultTable :type="1" />
      </b-tab>
      <b-tab
        title="หุ้น"
        lazy
      >
        <LottoResultTable :type="2" />
      </b-tab>
      <b-tab
        title="ยี่กี"
        lazy
      >
        <YeekeeResultTable :type="3" />
      </b-tab>
      <b-tab
        title="เลขชุด"
        lazy
      >
        <LottoResultTable :type="4" />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'

import LottoResultTable from '@/views/components/LottoResultTable.vue'
import YeekeeResultTable from '@/views/components/YeekeeResultTable.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    LottoResultTable,
    YeekeeResultTable,
  },
  data() {
    return {
      lastTab: 0,
    }
  },
  beforeMount() {
    const tab = localStorage.getItem('lotto-result-tab')
    if (tab) {
      this.lastTab = tab * 1
    }
  },
  methods: {
    changeTab(e) {
      this.lastTab = e
      localStorage.setItem('lotto-result-tab', this.lastTab)
    },
  },
}
</script>
