<template>
  <div>
    <div
      v-if="this.$store.state.app.isMaster(this.$store)"
      class="text-right mb-75"
    >
      <b-button
        v-if="systemStatus"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="danger"
        class="cusBtn"
        @click="toggleState"
      >
        <span class="align-middle">ปิดรับเเทงทั้งหมด</span>
      </b-button>
      <b-button
        v-else
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="teal"
        class="cusBtn"
        @click="toggleState"
      >
        <span class="align-middle">เปิดรับเเทง</span>
      </b-button>
    </div>
    <b-table
      responsive
      :fields="fields"
      :items="items"
      bordered
    >
      <template
        #cell(isLimit)="data"
      >
        <b-avatar
          v-if="data.value"
          square
          variant="primary"
        >
          <feather-icon icon="CheckIcon" />
        </b-avatar>
        <b-avatar
          v-else
          square
          variant="danger"
        >
          <feather-icon icon="XIcon" />
        </b-avatar>
      </template>
      <template #cell(status)="data">
        <b-button
          style="width: 200px;"
          pill
          :variant="statusTextColor[data.value]"
        >
          {{ statusText[data.value] }}
        </b-button>
      </template>
      <template #cell(action)="data">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          style="width: 125px;"
          @click="$router.push(`result/yeekee?id=${data.item.id}`)"
        >
          <feather-icon
            icon="FileIcon"
            class="mr-50"
          />
          <span class="align-middle">ข้อมูลรอบ</span>
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  BTable, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import lottoService from '@/service/lottoService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BTable,
    BButton,
    BAvatar,
    BRow,
    BCol,
  },
  props: {
    type: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      systemStatus: null,
      pagination: {
        rowsPerPage: 0,
      },
      loading: false,
      statusText: [
        'ยังไม่ถึงเวลาเเทง',
        'อยู่ระหว่างเปิดให้เเทง',
        'หยุดพักชั่วคราว',
        'หมดเวลาเเทง',
        'อยู่ระหว่างคำนวนผล',
        'อยู่ระหว่างคำนวนเครดิต',
        'คำนวนเครดิตเรียบร้อยเเล้ว',
        'รอจ่ายเครดิต',
        'อยู่ระหว่างจ่ายเครดิต',
        'อยู่ระหว่างคืนโพย',
        'ปิดรอบเเทงเรียบร้อยเเล้ว',
      ],
      statusTextColor: [
        'cyan-7',
        'teal',
        'danger',
        'warning',
        'secondary',
        'secondary',
        'dark',
        'warning',
        'secondary',
        'secondary',
        'info-2',
      ],
      fields: [
        {
          key: 'index',
          label: 'Id',
        },
        {
          key: 'name',
          label: 'ชื่อ',
          class: 'text-nowrap',
        },
        {
          key: 'roundDate',
          label: 'รอบวันที่',
          class: 'text-center',
          thStyle: {
            'min-width': '110px',
          },
        },
        {
          key: 'roundStartDate',
          label: 'เวลาเปิดเเทง',
          class: 'text-center',
          thStyle: {
            'min-width': '134px',
          },
        },
        {
          key: 'roundEndDate',
          label: 'เวลาปิดเเทง',
          class: 'text-center',
          thStyle: {
            'min-width': '130px',
          },
        },
        {
          key: 'lastUpdate',
          label: 'อัพเดตล่าสุด',
          class: 'text-center',
          thStyle: {
            'min-width': '130px',
          },
        },
        {
          key: 'status',
          label: 'สถานะ',
          class: 'text-center',
        },
        {
          key: 'action',
          label: 'ตั้งค่า',
          class: 'text-center text-nowrap',
        },
      ],
      items: [],
      interval: null,
    }
  },
  mounted() {
    if (this.$store.state.app.isMaster(this.$store) === false) {
      this.fields.pop()
    }
    this.LoadYeeKee()

    lottoService
      .getAuthen('yeekee/system-status')
      .then(res => {
        if (res.status === 200) {
          this.systemStatus = res.data.status
        }
      })

    this.interval = setInterval(() => {
      lottoService
        .getAuthen('yeekee/states')
        .then(res => {
          if (res.status === 200) {
            for (let i = 0; i < this.items.length; i += 1) {
              this.items[i].status = res.data[this.items[i].id]
            }
          }
        })
    }, 10000)
  },
  destroyed() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  },
  methods: {
    async LoadYeeKee() {
      this.loading = true
      const res = await lottoService.getAuthen('yeekee')
      setTimeout(() => {
        if (res.status === 200) {
          this.items = res.data
          this.loading = false
        }
      }, 100)
    },
    toggleState() {
      this.$swal({
        dark: true,
        title: 'Are you sure?',
        text: `คุณเเน่ใจว่าต้องการ ${
          this.systemStatus ? 'เปิด' : 'ปิด'
        } รับเเทงหวยยี่กีทั้งหมดใช่หรือไม่`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            this.systemStatus = !this.systemStatus
            lottoService
              .getAuthen(`yeekee/system-status/toggle?status=${this.systemStatus}`)
              .then(res => {
                if (res.status === 200) {
                  this.LoadYeeKee()
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'success',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'ดำเนินการสำเร็จ',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Error',
                      icon: 'AlertCircleIcon',
                      variant: 'danger',
                      text: res.data,
                    },
                  })
                }
              })
          }
        })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

@media (max-width: 768px) {

  .cusBtn {
    //margin-bottom: 5px;
    width: 100%;
  }
}
</style>
